import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { Input, Button } from '@windmill/react-ui'
import axios from "axios";
import Spinner from '../components/Buttons/Spinner'
import { ArrowNarrowRightIcon, ArrowNarrowLeftIcon } from '@heroicons/react/outline';
import { TrashIcon, PlusIcon } from '../icons/index'
import store from '../store';
import memberSlice from '../store/slices/member'
import teamSlice from '../store/slices/team';
import billingSlice from '../store/slices/billing';
import BillingPlanCard from '../components/Cards/BillingPlanCard';
import PhoneNumberForm from '../components/Forms/PhoneNumberForm';
import toast from 'react-hot-toast';









function NewTeam() {

  const BACKEND_URL = process.env.REACT_APP_SERVER_URL || window.env.REACT_APP_SERVER_URL;
  const account_url = `${BACKEND_URL}/v1/auth/account`
  const member_url = `${BACKEND_URL}/v1/auth/member`
  const billing_info_url = `${BACKEND_URL}/v1/billing/info`
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const qTeam = urlParams.get('team') // will be "null" if not present
  const qCode = urlParams.get('code') // will be "null" if not present
  const qMember = urlParams.get('member') // will be "null" if not present
  const token = store.getState().auth.token
  const user = store.getState().auth.account
  const headers = {'Authorization': `Bearer ${token}`}
  const configs = {headers: headers, skipAuthRefresh: true}
  const navigate = useNavigate()


  // survey questions
  const questions = [
    {
      'question': 'How did you hear about us?', 
      'answer': '',
      'choices': ['Meta', 'Product Hunt', 'LinkedIn', 'Google', 'YouTube', 'Other'],
      'index': 0,
    },
    {
      'question': 'Which best describes your company?', 
      'answer': '',
      'choices': ['Hosting', 'Web Design', 'Site Management ', 'Web Development', 'SaaS', 'Other'],
      'index': 1,
    },
    {
      'question': 'How large is your company?', 
      'answer': '',
      'choices': ['<10', '10-100', '100-500', '>500'],
      'index': 2,
    },
    {
      'question': 'Which role best describes you?', 
      'answer': '',
      'choices': ['Business Owner', 'Product Manager', 'Developer / Tester', 'Other'],
      'index': 3,
    },
  ]

  const [teamName, setTeamName] = useState('');
  const [team, setTeam] = useState('');
  const [phone, setPhone] = useState('');
  const [members, setMembers] = useState([{email: ''}, ]);
  const [survey, setSurvey] = useState(questions);
  const [sIndex, setSIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [view, setView] = useState('phone');




  useEffect(() => {
    // check if user has invite link
    preLoad()
  }, [token])


  const preLoad = async () => {

    if (!store.getState().auth.token){
      localStorage.setItem("next", window.location.href);
      navigate('/login')
    }

    if (qTeam !== undefined && qTeam !== null){
      let data = {
        'id': qMember,
        'code': qCode,
        'status': 'active'
      }
      // sending invite data 
      await axios.post(member_url, data, configs)
        .then((r) => {
          store.dispatch(memberSlice.actions.setMember(r.data))
        })
        .then(() => {
          axios.get(account_url, configs)
            .then((r) => {
              store.dispatch(teamSlice.actions.setTeam(r.data))
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .then(() => {
          axios.post(billing_info_url, null, configs)
            .then((r) => {
              store.dispatch(billingSlice.actions.setBilling(r.data))
              navigate('/onboard');
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
        })
    }
    else {
      // checking if already a Member
      await axios.get(member_url, configs)
        .then((r) => {
          store.dispatch(memberSlice.actions.setMember(r.data))
          axios.get(`${account_url}`, configs)
            .then((r) => {
              store.dispatch(teamSlice.actions.setTeam(r.data))
              // check if account type is new
              if (r.data.type==='new'){
                setReady(true)
                setSurvey(questions)
                setView('survey')
              }else {
                console.log(r.data)
                navigate('/onboard');
              }
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
          console.log('user not yet a member')
          setReady(true)
        })
      
    }
  }


  const handleKeyDownTeam = (evt) => {
    if (evt.key === 'Enter') {
      createTeam()
    }
  }


  const handleKeyDownMemebr = (evt) => {
    if (evt.key === 'Enter') {
      sendInvites()
    }
  }


  const createTeam = async () => {
    let data = {
      'name': teamName,
      'type': 'new'
    }
    if (teamName.length <= 0 || loading ){return null}
    setLoading(true)
    await axios.post(account_url, data, configs)
      .then((r) => {
        // saving account
        setTeam(r.data)
        store.dispatch(teamSlice.actions.setTeam(r.data))

        // create member data
        let data = {
          'account': r.data.id,
          'email': user.username,
          'status': 'active',
          'type': 'admin',
          'code': r.data.code,
          'phone': phone,
        }

        // create admin member
        axios.post(member_url, data, configs)
          .then((r) => {
            
            // associate admin member with current user
            axios.post(member_url, {'id': r.data.id}, configs)
              .then((r) => {
                store.dispatch(memberSlice.actions.setMember(r.data))
                setView('member')
                setLoading(false)
              })
              .catch((e) => {
                console.log(e)
                setLoading(false)
              })

          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }


  const sendInvites = async () => {
    if (members[0]['email'].length <= 0 || !members[0]['email'].includes('@') || loading) {return null}
    setLoading(true)
    for (let i in members){
      let data = {
        'account': team.id,
        'email': members[i]['email'],
        'status': 'pending',
        'type': 'contributor',
        'send_invite': true
      }
      await axios.post(member_url, data, configs)
        .then((r) => {
          console.log('invite sent')
        })
        .catch((e) => {
          setLoading(false)
          toast.error('Contact support')
          console.log(e)
          
        })
    }
    toast.success(`Invite${members.length > 1 ? 's' : ''} sent`)
    setLoading(false)
    setView('survey')
  }


  const handleMemberChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...members];
    list[index][name] = value;
    setMembers(list);
  };


  const handleRemoveMember = index => {
    const list = [...members];
    list.splice(index, 1);
    setMembers(list);
  };
  

  const handleAddMember = () => {
    setMembers([...members, {email: ''}]);
  };


  const billingCallBack = async (plan) => {
    console.log(`current plan is -> ${plan}`)
    setLoading(true)
    let data = {
      'id': store.getState().team.team.id,
      'type': plan,
      'info': {'survey': survey}
    }
    await axios.post(account_url, data, configs)
      .then((r) => {
        setLoading(false)
        store.dispatch(teamSlice.actions.setTeam(r.data))
        navigate('/onboard')
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        toast.error('Contact support')
      })
  } 


  const updateSurvey = (index, value) => {
    let _survey = [...survey]
    _survey[index]['answer'] = value
    setSurvey(_survey)
  }




  return (
    
    <div className="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
      { ready && 
        <div className="flex-1 h-full max-w-lg mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800">

          { view === 'phone' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Phone Number
                </h1>
                <div>
                  <PhoneNumberForm 
                    callBack={setPhone}
                  />
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-4 ${(phone.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={() => setView('team')} 
                    disabled={phone.length <= 13 || loading}
                  >
                    <Spinner display={loading}/>
                    Save
                  </Button>
                </div>
            </div>
          }
          
          { view === 'team' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  New Team
                </h1>
                <div>
                  <Input 
                    className="rounded-md p-2 mt-1" 
                    onKeyDown={handleKeyDownTeam}
                    type="text"
                    placeholder="Team Name" 
                    value={teamName}
                    onChange={e => setTeamName(e.target.value)}/>
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-4 ${(teamName.length > 0 && !loading)? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={createTeam} 
                    disabled={teamName.length <= 0 || loading}
                  >
                    <Spinner display={loading}/>
                    Create Team
                  </Button>
                </div>
            </div>
          }

          { view === 'member' && 
            <div className="p-6 sm:p-12 ">
                <h1 id='title' className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Invite Members
                </h1>
                <div>
                  {members.map((x, i) => {
                    return(
                      <div className='flex justify-start' key={i}>
                        <Input 
                          className="rounded-md p-2 mt-1 mr-1" 
                          onKeyDown={handleKeyDownMemebr}
                          type="text"
                          placeholder="jane@example.com" 
                          value={x.email}
                          name='email'
                          onChange={e => handleMemberChange(e, i)}
                        />
                        <div className="my-2" style={{ width: '5rem',}}>
                          {members.length !== 1 &&  <Button onClick={() => handleRemoveMember(i)} layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                          {members.length - 1 === i && <Button onClick={() => handleAddMember(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                        </div>
                      </div>
                    )
                      
                  })}  
                  <Button 
                    type="submit" 
                    block 
                    className={`mt-8 ${(members[0]['email'].length > 0 && members[0]['email'].includes('@') && !loading) ? 'cursor-pointer' : 'cursor-not-allowed'}`} 
                    onClick={sendInvites} 
                    disabled={members[0]['email'].length <= 0 || !members[0]['email'].includes('@') || loading}
                  >
                    <Spinner display={loading}/>
                    Send Invites
                  </Button>
                  <div className='flex justify-end'>
                    <span className='hover:text-blue-600 text-blue-500 mt-6 text-xs text-right cursor-pointer' onClick={() => setView('survey')}>Skip for now</span>
                  </div>
                </div>
            </div>
          }

          { view === 'survey' && 
            <div className="p-6 sm:p-12 ">
              <h1 id='survey-question' className="mb-4 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">
                {questions[sIndex].question}
              </h1>
              
              {/* answer */}
              <div className='mx-auto'>
                <div className='mt-8 flex flex-wrap justify-center gap-4 mx-auto'>
                  {questions[sIndex].choices.map((choice, i) => (
                    <div 
                      className={`hover:-translate-y-1 duration-200 ease-in-out cursor-pointer rounded-md ring-2 px-3 py-2 font-semibold text-sm  ${survey[sIndex].answer === choice ? 'ring-blue-500 text-blue-500 dark:bg-gray-800 bg-gray-100' : 'ring-gray-400 text-gray-500 dark:text-gray-400 dark:bg-gray-800 bg-gray-100'}`}
                      key={i}
                      onClick={() => {updateSurvey(sIndex, choice)}}
                    >
                      {choice}
                    </div>
                  ))}
                </div>
              </div>
              
              {/* next arrows */}
              <div className='flex justify-between'>
                
                <div 
                  className='cursor-pointer text-sm hover:text-blue-600 text-blue-500 max-w-sm'
                  onClick={() => {
                    setSIndex(sIndex-1)
                  }}
                >
                  {sIndex > 0 &&
                  <div className='flex mt-6'>
                    <ArrowNarrowLeftIcon className='w-7'/>
                    <div><p className="ml-1 mt-1">back</p></div>
                  </div>
                  }
                </div>

                
                <div 
                  className='flex cursor-pointer text-sm hover:text-blue-600 text-blue-500 max-w-sm' 
                  onClick={() => {
                    if ((sIndex+1) >= questions.length){
                      setView('billing')
                    } else {
                      setSIndex(sIndex+1)
                    }
                  }}
                >
                  {survey[sIndex].answer.length > 0 && 
                    <div className='flex mt-6'>
                      <div><p className="mr-1 mt-1">next</p></div>
                      <ArrowNarrowRightIcon className='w-7'/>
                    </div>
                  }
                </div>

              </div>

            </div>
          }

          { view === 'billing' && 
            <div>
              <BillingPlanCard callBack={billingCallBack}/>
              <div className='flex justify-end'>
                <span className='hover:text-blue-600 text-blue-500 mr-12 -mt-6 mb-6 text-xs text-right cursor-pointer' onClick={() => billingCallBack('free')}>Skip for now</span>
              </div>
            </div>
          }

        </div>
      }
    </div>
   
  )
}

export default NewTeam
